<template>
  <div class="school-progress">
    <!-- Top Section: Logo & School Name -->
    <div class="top-section">
      <div class="logo-container">
        <img :src="schoolDetail.logo_url" alt="School logo" />
      </div>
      <div class="school-name">
        {{ schoolDetail.school_name }}
      </div>
      
    </div>
    <div class="school-message">
       <CampaignMessage/>
      </div>
    <!-- Progress Section -->
    <div class="progress-section">
      <div class="raised-info" v-if="schoolDetail.original_total_fund_raised">
        ${{
          schoolDetail.original_total_fund_raised_50.toLocaleString("en-US")
        }}
        <span v-if="schoolDetail.school_goal">
          raised of ${{ schoolDetail.school_goal_50.toLocaleString("en-US") }}
          School goal
        </span>
      </div>
      <div class="progress-bar-wrapper">
        <div
          class="progress-bar"
          :style="{ width: schoolDetail.original_fund_raise_percentage + '%' }"
        >
          <strong>{{ schoolDetail.original_fund_raise_percentage }}%</strong>
        </div>
      </div>
      <div class="donors-days" v-if="schoolDetail.total_donor">
        <span class="donors">
          {{ schoolDetail.total_donor.toLocaleString("en-US") }} donors
        </span>
        <span class="days">
          {{ getCampaign.campaign_detail.remaining_day }} days left
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CampaignMessage from "../../../components/PublicDonationPages/CampaignMessage/CampaignMessage.vue"

export default {
  name: "StudentCardsGoal",
  components: {
    CampaignMessage,
  },
  data() {
    return {
      schoolDetail: {},
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
  },
  methods: {
    getSchoolDetail() {
      this.schoolDetail = this.getCampaign.campaign_detail.school_detail;
    },
  },
  mounted() {
    this.getSchoolDetail();
  },
};
</script>

<style scoped>
.school-progress {
  max-width: 100vw;
  margin-top: 8px;
  padding: 20px;
  background-color: #ffff;
  border-bottom: 12px solid #512e96;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-rows: auto 3fr auto;
  grid-template-columns: auto;
  grid-template-areas: 
  'schoolName'
  'schoolMessage'
  'progressBar'
  ;
}
.school-message{
 grid-area: schoolMessage;
 display: grid;
}
.school-name {
  grid-area: schoolName;
  flex: 1;
  font-family: "Roboto Slab", serif;
  font-size: 27px;
  font-weight: 700;
  color: #2c1963;
  margin-left: 20px;
}

/* Progress Section */
.progress-section {
  grid-area: progressBar;
  margin-top: 20px;
}


/* Top Section */
.top-section {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-self: center;

}

.logo-container {
  flex: 0 0 auto;
}

.logo-container img {
  width: 100px;
  height: auto;
}

.raised-info {
  font-size: 24px;
  color: #2c1963;
}

.raised-info span {
  color: #2d2d2d;
  font-size: 16px;
  margin-left: 10px;
}

.progress-bar-wrapper {
  background: #d3d3d3;
  border-radius: 20px;
  overflow: hidden;
  height: 25px;
  margin-top: 10px;
  max-width: auto;
}

.progress-bar {
  background: #4a3290;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.donors-days {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin-top: 10px;
}

/* Responsive Styles for Small Screens */
@media (max-width: 600px) {
  .logo-container img {
    width: 70px;
  }
  .school-name {
    font-size: 20px;
    margin-left: 10px;
  }
  .raised-info {
    font-size: 20px;
  }
  .donors-days {
    flex-direction: row;
    align-items: flex-start;
    font-size: 16px;
  }
  .donors-days .days {
    margin-top: 5px;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  API_PDP_STUDENT_LIST,
  API_ADMIN_GET_SCHOOL_DETAIL,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { ID } from "@/constants/APIKeys";
import { STUDENT } from "@/constants/ModuleKeys";
import { ROUTER_URL } from "@/constants/urls";
export default {
  name: "StudentCardsGoal",
  data() {
    return {
      studentList: [],
      schoolDetail: {},
      currentStudent: null,
    };
  },
  created() {
    this.$root.$refs.studentCard = this;
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
    schoolFundRaisedPercentage() {
      return Math.round(
        (this.schoolDetail.total_fund_raised / this.schoolDetail.school_goal) *
          100
      );
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleCreateStudentModal: "publicDonationPage/toggleCreateStudentModal",
      updateSelectedStudent: "publicDonationPage/updateSelectedStudent",
    }),
    selectStudent(id) {
      let student = this.studentList.find((student) => student.id === id);
      this.updateSelectedStudent({ student });
      this.$router.push({
        name: ROUTER_URL.publicParentDashboard.name,
        query: { ...this.$route.query, [STUDENT]: student[ID] },
      });
      this.getStudentListData();
    },
    filterStudent(list) {
      // console.log(list);
      this.studentList = list;
      console.log(this.studentList);
      let index = this.studentList.findIndex(
        (item) => item.id == this.currentStudent
      );
      this.studentList.splice(index, 1);
    },
    getStudentListData() {
      const _this = this;
      _this.loading = true;
      const successHandler = (res) => {
        // _this.studentList = res.data.student_list;
        this.filterStudent(res.data.student_list);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getSchoolDetail();
      };
      let formData = {};
      return Axios.request_GET(
        API_PDP_STUDENT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getSchoolDetail() {
      const successHandler = (res) => {
        this.schoolDetail = res.data.school_detail;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["school_id"] = this.getCampaignDetail.student_detail.school_id;
      formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id;
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
  },
  mounted() {
    this.currentStudent = this.$route.query.student;
    this.getStudentListData();
  },
};
</script>
